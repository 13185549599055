import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./CartSidebar.css";
import PhoneOtpModal from "./PhoneOtpModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { routeurl, weburl } from "../helpers/ApiUrls";
import { Link } from "react-router-dom";
import { useThemeContext } from "../helpers/ThemeContext";
const CartSidebar = ({
    onUpdateCart,
    isOpen,
    onClose,
    onProceedToCheckout,
}) => {
    const {
        handleUpdateCart,
        handleQuantityChange,
        cartItems,
        handleDeleteItem,
    } = useThemeContext();

    const [isMinimized, setIsMinimized] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isPhoneOtpModalVisible, setIsPhoneOtpModalVisible] = useState(false);
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const [mapKey, SetMapKey] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const [isRange, setIsRanger] = useState(false);

    const [searchPickupResults, setSearchPickupResults] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [AllStore, setAllStore] = useState([]);
    const [LoadingPayment, setLoadingPayment] = useState(false);

    const [mylocation, setMylocation] = useState({
        lat: null,
        lng: null,
    });

    useEffect(() => {
        const scriptTags = document.getElementsByTagName("script");
        for (let i = 0; i < scriptTags.length; i++) {
            const src = scriptTags[i].src;
            const match = src.match(/key=([^&]+)/);
            if (match) {
                SetMapKey(match[1]);
            }
        }
    }, []);

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value.trim() === "") {
            setSearchResults([]);
            return;
        }
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions(
            { input: e.target.value },
            (predictions, status) => {
                if (
                    status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                    setSearchResults(predictions);
                } else {
                    setIsRanger(false);
                    setSearchResults([]);
                }
            }
        );
    };

    const [checkoutData, setCheckoutData] = useState({
        addressType: "",
        flatHouseNo: "",
        floor: "",
        landmark: "",
        name: "",
        phone: "",
        payLater: false,
        selectedAddress: "",
    });
    // Retrieve user details from localStorage
    const userId = localStorage.getItem("userId");
    const userPhone = localStorage.getItem("phoneNumber"); // Assuming you have saved phone number here
    const tokenSto = localStorage.getItem("authToken");
    //const savedCheckoutData = localStorage.getItem("checkoutData");
    //console.log("product", savedCheckoutData);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isOpen) {
            setIsMinimized(true);
        }
    }, [isOpen]);

    const [Userdata, setUserdata] = useState([]);

    // Haversine formula to calculate the distance between two lat/lon points in kilometers
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const toRad = (x) => (x * Math.PI) / 180;

        const R = 6371; // Radius of Earth in km
        const deltaLat = toRad(lat2 - lat1);
        const deltaLon = toRad(lon2 - lon1);

        const a =
            Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(toRad(lat1)) *
                Math.cos(toRad(lat2)) *
                Math.sin(deltaLon / 2) *
                Math.sin(deltaLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in kilometers
    };

    // Check if my location is within distance_cover for any shop
    const checkProximity = (long, lat, locations) => {
        console.log("checkProximity", long, lat, locations);
        let isWithinRange = false;

        for (let location of locations) {
            const shopLat = parseFloat(location.latitude);
            const shopLon = parseFloat(location.longitude);
            const shopDistanceCover = parseFloat(location.distance_cover);

            // Calculate distance between my location and shop
            const distance = calculateDistance(
                parseFloat(lat),
                parseFloat(long),
                shopLat,
                shopLon
            );

            // If the distance is within the shop's coverage area
            if (distance <= shopDistanceCover) {
                isWithinRange = true;
                break;
            }
        }

        if (!isWithinRange) {
            setIsRanger(false);
            setCheckoutData((prevData) => ({
                ...prevData,
                flatHouseNo: "",
            }));
            setSearchValue("");
            toastr.error("Sorry Your Location is not in range!");
        } else {
            toastr.success("location is in Range.");
            setIsRanger(true);
        }
    };

    const getuserdata = async (userId) => {
        try {
            // Send the data to the server
            const response = await fetch(`${weburl}/getuserbyid/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
            });
            // Check if the response is okay
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Something went wrong");
            }

            const data = await response.json();
            console.log("User data:", data);
            setUserdata(data);
            setCheckoutData((prevData) => ({
                ...prevData,
                name: data.user?.name || "",
                phone: data.user?.phone_number || "",
            }));

            const lastAddress = data.addresses[data.addresses.length - 1];
            const locations = data.shops;
            setAllStore(locations);
            if (lastAddress) {
                setCheckoutData((prevData) => ({
                    ...prevData,
                    name: lastAddress.shipping_name || "",
                    phone: lastAddress.phone || "",
                    flatHouseNo: lastAddress.house_no || "",
                    addressType: lastAddress.type || "",
                    landmark: lastAddress.landmark || "",
                    selectedAddress: lastAddress.id,
                    longitude: lastAddress.longitude,
                    latitude: lastAddress.latitude,
                }));
                setSearchValue(lastAddress.house_no || "");

                checkProximity(
                    lastAddress.longitude,
                    lastAddress.latitude,
                    locations
                );
            } else {
                setCheckoutData((prevData) => ({
                    ...prevData,
                    selectedAddress: "new",
                }));
            }

            console.log("lastAddresslastAddress", data);
            // Handle the user data as needed
        } catch (error) {
            setIsRanger(false);
            console.error("Error saving order:", error);
        }
    };
    useEffect(() => {
        if (userId) {
            getuserdata(userId);
        }
    }, [userId]);

    const ChangeLocation = (data) => {
        if (data) {
            console.log(data);
            setCheckoutData((prevData) => ({
                ...prevData,
                name: data.shipping_name || "",
                phone: data.phone || "",
                flatHouseNo: data.house_no || "",
                addressType: data.type || "",
                landmark: data.landmark || "",
                selectedAddress: data.id,
            }));
            setSearchValue(data.house_no);
            checkProximity(data.longitude, data.latitude, AllStore);
        } else {
            setCheckoutData((prevData) => ({
                ...prevData,
                name: "",
                phone: Userdata.user?.phone_number,
                flatHouseNo: "",
                addressType: "",
                landmark: "",
                selectedAddress: "new",
            }));
            setSearchValue("");
        }
    };

    // Function to save data in localStorage whenever checkoutData is updated
    {
        /*useEffect(() => {
        if (checkoutData.phone) {
            // Save checkoutData and phone to localStorage
            localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
            localStorage.setItem("phoneNumber", checkoutData.phone);
        }
    }, [checkoutData]); // This effect runs every time checkoutData is updated
    useEffect(() => {
        // Retrieve saved data from localStorage
        const savedPhoneNumber = localStorage.getItem("phoneNumber");
        const savedCheckoutData = localStorage.getItem("checkoutData");
    
        // If both phone number and checkoutData are available
        if (savedPhoneNumber && savedCheckoutData) {
            const parsedCheckoutData = JSON.parse(savedCheckoutData);
    
            // Set the state with the saved data if phone matches
            if (parsedCheckoutData.phone === savedPhoneNumber) {
                setCheckoutData(parsedCheckoutData); 
            }
        }
    }, []); // Run this effect on component mount
    */
    }
    // useEffect(() => {
    //     const savedPhoneNumber = localStorage.getItem("phoneNumber");
    //     const savedCheckoutData = localStorage.getItem("checkoutData");

    //     if (savedPhoneNumber && savedCheckoutData) {
    //         const parsedCheckoutData = JSON.parse(savedCheckoutData);

    //         // Ensure the phone number matches the one in checkoutData
    //         if (parsedCheckoutData.phone === savedPhoneNumber) {
    //             setCheckoutData(parsedCheckoutData);
    //         }
    //     }
    // }, []); // Run only once on component mount

    // useEffect(() => {
    //     const savedCheckoutData = localStorage.getItem("checkoutData");
    //     if (savedCheckoutData) {
    //         setCheckoutData(JSON.parse(savedCheckoutData)); // Set state from localStorage
    //     }
    // }, []);

    const totalAmount = cartItems.reduce(
        (acc, item) =>
            acc + (item.attribute.discount_cost || 0) * item.quantity,
        0
    );
    const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

    const showPhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(true);
    };

    const hidePhoneOtpModal = () => {
        setIsPhoneOtpModalVisible(false);
        setIsCheckoutVisible(true); // Show checkout form after OTP verification
    };

    const handleCheckout = () => {
        const tokenSto = localStorage.getItem("authToken"); // Check if token exists
        if (tokenSto) {
            setIsCheckoutVisible(true);
            handleSaveAddress();
        } else {
            showPhoneOtpModal();
        }
    };

    {
        /*const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCheckoutData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };*/
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCheckoutData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    //const cartItemsArray = Array.isArray(cartItems) ? cartItems : Object.values(cartItems);
    //const cartItemsArray = Object.keys(cartItems).map((key) => cartItems[key]);
    const cartItemsArray = Array.isArray(cartItems)
        ? cartItems
        : Object.values(cartItems);

    const handleSaveAddress = async (e) => {
        if (!isRange || searchResults.length !== 0) {
            toastr.error("Sorry Your Location is not in range!");
            return null;
        }

        const userId = localStorage.getItem("userId");
        // Prepare the data to be sent to the server
        // {name: data.shipping_name || "",
        //     phone: data.phone || "",
        //     flatHouseNo: data.house_no || "",
        //     addressType: data.type || "",
        //     landmark: data.landmark || "",
        //     selectedAddress: data.id,}
        if (
            checkoutData.name.length === 0 &&
            checkoutData.house_no.length === 0 &&
            checkoutData.phone.length === 0 &&
            checkoutData.landmark.length === 0 &&
            checkoutData.type.length === 0
        ) {
            alert("Please fill in all the required fields");
            return null;
        }
        const orderData = {
            ...checkoutData,
            cartItems: JSON.stringify(cartItemsArray), // Convert to JSON string
            //cartItems: cartItemsArray,
            totalAmount: totalAmount,
            flatHouseNo: searchValue,

            user_id: userId, // Ensure user_id is included here
        };

        console.log("Order Data:", orderData); // Debugging line

        try {
            setLoadingPayment(true);
            // Send the data to the server
            const response = await fetch(`${weburl}/api/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                    "X-CSRF-TOKEN": document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content"),
                },
                body: JSON.stringify(orderData), // Convert orderData to JSON string
            });
            const data = await response.json();

            console.log(data);
            if (response.ok) {
                console.log("Order placed successfully:", data);
                toastr.success("Order placed successfully!");

                localStorage.removeItem("cartItems");

                // Optionally, clear the cart state in your application
                // onUpdateCart([]);
                handleUpdateCart([]);
                setIsCheckoutVisible(false);
                // Redirect to the orders page without refreshing the browser
                //  navigate("/admart-new/account/orders");

                navigate(`${routeurl}account/orders`); // Change the path to the actual orders route
                // Trigger the checkout process or redirect the user
                //onProceedToCheckout();
            } else {
                // Handle server errors
                const errorData = await response.json();
                console.error(
                    "Order creation failed:",
                    error.response.data.error || error.response.data.message
                );

                //console.error('Order creation failed:', errorData.message);
                // Show error message with toastr
                // toastr.error(
                //     "Order creation failed: " +
                //         (errorData.message || "Unknown error")
                // );
            }
        } catch (error) {
            console.error("Error saving order:", error);
        } finally {
            setLoadingPayment(false);
        }
    };

    const tokenCheckout = localStorage.getItem("authToken"); // Check if token exists

    const toggleCart = () => {
        const cart = document.getElementById("mycart");
        if (cart) {
            cart.classList.toggle("show");
        }
    };

    const clearAll = () => {
        setSearchResults([]);
        setSearchValue("");
    };

    const handleSearchResultClick = (result) => {
        setSearchResults([]);
        setSearchValue(result.description);

        // Save selected location description in local storage
        localStorage.setItem("selectedLocation", result.description);

        // Example: You may need to fetch additional details to get latitude and longitude
        // This depends on your API and how you retrieve location details

        // For example, if you have a place_id, you might fetch details using Google Maps Places API
        // Here's a basic example, assuming you have access to a Places API service

        const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
        );

        service.getDetails({ placeId: result.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const { lat, lng } = place.geometry.location;

                const latitudev = lat();
                const longitudev = lng();

                checkProximity(longitudev, latitudev, AllStore);

                setCheckoutData((prevData) => ({
                    ...prevData,
                    longitude: longitudev,
                    latitude: latitudev,
                }));
                // Store latitude and longitude in localStorage
                localStorage.setItem("latitude", lat());
                localStorage.setItem("longitude", lng());

                // Optionally, you can update the state or perform other actions here
            } else {
                setIsRanger(false);
                console.error("Error fetching place details:", status);
            }
        });
    };

    return (
        <div className="cart-slide" id="mycart">
            <div className="d-flex flex-column" style={{ height: "100vh" }}>
                <div className="d-flex align-items-center p-4">
                    <button
                        className="close-button bg-white"
                        aria-label="Close"
                        onClick={toggleCart}
                    >
                        <span aria-hidden="true" className="h2">
                            &times;
                        </span>
                    </button>

                    <h4>Shopping Cart</h4>
                </div>
                <div
                    className={`bg-light p-3 cart-sidebar h-100`}
                    style={{ overflow: "auto" }}
                >
                    {cartItems.length > 0 && (
                        <ul>
                            {cartItems.map((item) => {
                                const discountCost =
                                    Number(item.attribute.discount_cost) || 0;
                                const originalCost =
                                    Number(item.attribute.cost) || 0;

                                return (
                                    <li
                                        key={`${item.product.id}-${item.attribute.id}-${item.shopId}`}
                                        className="cart-item bg-white p-3 rounded border"
                                    >
                                        <img
                                            src={`${weburl}/${item.product.image}`}
                                            alt={item.product.name}
                                            className="cart-item-image"
                                        />
                                        <div className="cart-item-details">
                                            <p>{item.product.name}</p>
                                            <p>
                                                Attribute: {item.attribute.name}
                                            </p>
                                            <p>
                                                <span className="discounted-price">
                                                    ₹{discountCost.toFixed(2)}
                                                </span>
                                                <span className="original-price">
                                                    ₹{originalCost.toFixed(2)}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="d-block">
                                            <div className="custom-quantity-controls bg-primary rounded mb-2">
                                                <button
                                                    className="bg-transparent"
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            item.product,
                                                            item.shopId,
                                                            item.attribute,
                                                            item.quantity - 1
                                                        )
                                                    }
                                                    aria-label="Decrease quantity"
                                                    disabled={
                                                        item.quantity <= 1
                                                    }
                                                >
                                                    -
                                                </button>
                                                <span className="text-white px-2">
                                                    {item.quantity}
                                                </span>
                                                <button
                                                    className="bg-transparent"
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            item.product,
                                                            item.shopId,
                                                            item.attribute,
                                                            item.quantity + 1
                                                        )
                                                    }
                                                    aria-label="Increase quantity"
                                                    disabled={
                                                        item.quantity >=
                                                        (item.attribute
                                                            .stock_quantity ||
                                                            Infinity)
                                                    }
                                                >
                                                    +
                                                </button>
                                                {errorMessage && (
                                                    <p className="error-message">
                                                        {errorMessage}
                                                    </p>
                                                )}
                                            </div>

                                            <button
                                                onClick={() =>
                                                    handleDeleteItem(
                                                        item.product,
                                                        item.shopId,
                                                        item.attribute
                                                    )
                                                }
                                                className="delete-button w-100 rounded"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                    )}
                    <div className="d-block "></div>

                    <div
                        className={`cart-summary ${
                            cartItems.length === 0 && "border-0"
                        }`}
                    >
                        <h5>Total Items: {totalItems}</h5>
                        <h5>Total Amount: ₹{totalAmount.toFixed(2)}</h5>
                    </div>

                    {isPhoneOtpModalVisible && !tokenCheckout && (
                        <PhoneOtpModal
                            isOpen={isPhoneOtpModalVisible}
                            onClose={hidePhoneOtpModal}
                            onVerify={(phoneNumber) => {
                                hidePhoneOtpModal();
                            }}
                        />
                    )}
                    {isPhoneOtpModalVisible && (
                        <PhoneOtpModal onClose={hidePhoneOtpModal} />
                    )}

                    {cartItems.length > 0 && tokenCheckout && (
                        <>
                            <hr />
                            <h4>Save Address</h4>

                            {Userdata.length !== 0 &&
                                Userdata.addresses.length !== 0 &&
                                Userdata.addresses.map((address, index) => (
                                    <div
                                        className="form-check"
                                        key={address.id}
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="selectedAddress"
                                            defaultChecked={
                                                index ===
                                                Userdata.addresses.length - 1
                                            } // Check if it's the last input
                                            id={`address-${address.id}`} // unique ID for each address
                                            value={address.user_id || ""}
                                            onChange={() => {
                                                handleChange; // Call handleChange
                                                ChangeLocation(address); // Call ChangeLocation
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`address-${address.id}`}
                                        >
                                            <b className="pb-0">
                                                {address.shipping_name},{" "}
                                                {address.pincode}{" "}
                                                {/* Adjust according to your address object */}
                                                <span className="badge bg-primary ms-2">
                                                    {address.type}
                                                </span>
                                            </b>
                                            <p
                                                className="pt-0"
                                                style={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    display: "inline-block",
                                                    width: 250,
                                                    maxWidth: "100%",
                                                }}
                                            >
                                                {address.house_no}{" "}
                                                {/* Adjust according to your address object */}
                                            </p>
                                        </label>
                                    </div>
                                ))}

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selectedAddress"
                                    id="flexRadioDefault22"
                                    checked={
                                        Userdata.length !== 0 &&
                                        Userdata.addresses.length === 0
                                    }
                                    value={"new"}
                                    onChange={() => {
                                        handleChange; // Call handleChange
                                        ChangeLocation(); // Call ChangeLocation
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault22"
                                >
                                    <b className="pb-0">
                                        Add Address{" "}
                                        <i className="ri-map-pin-2-fill"></i>
                                    </b>
                                    <p
                                        className="pt-0"
                                        style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            display: "inline-block",
                                            width: 250,
                                            maxWidth: "100%",
                                        }}
                                    >
                                        {" "}
                                        <i class="ri-add-circle-line"></i> Add
                                        more address
                                    </p>
                                </label>
                            </div>

                            <hr />
                        </>
                    )}

                    {tokenCheckout && cartItems.length > 0 && (
                        <div className="checkout-form">
                            <h4>Checkout</h4>
                            <form>
                                <div>
                                    <label className="w-100 mb-2">
                                        Save Address As
                                        <select
                                            style={{ display: "block" }}
                                            name="addressType"
                                            className="form-control myselect w-100"
                                            value={checkoutData.addressType}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="Home">Home</option>
                                            <option value="Work">Work</option>
                                            <option value="Hotel">Hotel</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Flat/House No/Building Name
                                        <div className="mb-2 input-group input-group-icon input-rounded">
                                            <input
                                                type="text"
                                                id="location-input"
                                                className="form-control dz-password"
                                                placeholder="Search for a location..."
                                                value={searchValue}
                                                onChange={handleSearchChange}
                                            />
                                            <span
                                                className="input-group-text show-pass py-0 px-2 cursor "
                                                onClick={clearAll}
                                            >
                                                {searchValue !== "" && (
                                                    <i className="ri-close-circle-fill "></i>
                                                )}
                                            </span>
                                        </div>
                                        {searchResults &&
                                            searchResults.length > 0 && (
                                                <div className="container">
                                                    <div className="title-bar mb-4 d-flex align-items-center justify-content-between">
                                                        <h5 className="title mb-0 mt-4">
                                                            Search history
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            onClick={clearAll}
                                                            className="font-w500 font-10 border-0 bg-danger p-0 px-2"
                                                        >
                                                            Clear All
                                                        </button>
                                                    </div>
                                                    <div className="recent-search-list">
                                                        <ul>
                                                            {searchResults.map(
                                                                (result) => (
                                                                    <li
                                                                        className="search-content cursor justify-content-between"
                                                                        key={
                                                                            result.place_id
                                                                        }
                                                                        onClick={() =>
                                                                            handleSearchResultClick(
                                                                                result
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="left-content d-flex gap-2">
                                                                            <i className="ri-map-pin-2-fill"></i>
                                                                            <div>
                                                                                <p className="fw-bold mb-0">
                                                                                    {
                                                                                        result
                                                                                            .structured_formatting
                                                                                            .main_text
                                                                                    }{" "}
                                                                                </p>
                                                                                <span>
                                                                                    {
                                                                                        result
                                                                                            .structured_formatting
                                                                                            .secondary_text
                                                                                    }{" "}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="remove ">
                                                                            <i class="ri-arrow-right-up-line"></i>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        {/* <textarea
                                            style={{ height: 80 }}
                                            className="form-control w-100"
                                            name="flatHouseNo"
                                            value={
                                                checkoutData.flatHouseNo || ""
                                            }
                                            onChange={handleChange}
                                            required
                                        /> */}
                                    </label>
                                </div>

                                <div>
                                    <label className="mb-2 w-100">
                                        Enter Full Address With Pincode
                                        <input
                                            type="text"
                                            className="form-control w-100"
                                            name="landmark"
                                            value={checkoutData.landmark || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>

                                <div>
                                    <label className="mb-2 w-100">
                                        Your Name
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control w-100"
                                            value={checkoutData.name || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-2 w-100">
                                        Phone Number
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form-control w-100"
                                            value={checkoutData.phone || ""}
                                            onChange={handleChange}
                                            required
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="payLater"
                                            checked={checkoutData.payLater}
                                            onChange={handleChange}
                                        />
                                        Pay Later
                                    </label>
                                </div>
                                {/* <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary py-2 px-3 me-auto w-100 mt-2 "
                                    >
                                        SAVE AND CHECKOUT
                                    </button>
                                </div> */}
                            </form>
                        </div>
                    )}
                </div>
                <div className="d-block p-2">
                    {cartItems.length === 0 ? (
                        <Link
                            to={weburl} // Correct interpolation of variable
                            className="btn btn-dark bg-dark py-2 px-3 w-100"
                            disabled={cartItems.length === 0}
                        >
                            <i className="ri-home-3-fill"></i> Go Home
                        </Link>
                    ) : LoadingPayment ? (
                        <button className="checkout-button d-flex justify-content-center gap-2 align-items-center">
                            <span className="h5 m-0 me-2">
                                {" "}
                                Processing order{" "}
                            </span>{" "}
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    ) : (
                        <button
                            className="checkout-button d-flex justify-content-between gap-2 align-items-center"
                            disabled={cartItems.length === 0}
                            onClick={handleCheckout}
                        >
                            <div className="d-block">
                                <p
                                    className="p m-0 fw-bold"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    ₹{totalAmount.toFixed(2)}
                                </p>
                                <p
                                    className="p m-0"
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    TOTAL{" "}
                                </p>
                            </div>
                            <span className="h5 ">
                                {" "}
                                Proceed to Checkout{" "}
                                <i className="ri-arrow-right-s-line"></i>
                            </span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

CartSidebar.propTypes = {
    cartItems: PropTypes.array.isRequired,
    onUpdateCart: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProceedToCheckout: PropTypes.func.isRequired,
};

export default CartSidebar;
